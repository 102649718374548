import { useState, useRef, Fragment } from "react";

// Libraries
import { useTransition } from "react-spring";

// Variables
import { types } from "../config";

// Components
import Card from "./cards";
import Selectors from "./selectors";

function List({ list, completed = [], setAnswer, setCompleted }) {
    const done = useRef(new Set(completed));
    const direction = useRef(1);

    const filterQuestions = () =>
        list.filter((item) => !done.current.has(item.id))[0];

    const [currentItem, setCurrentItem] = useState(filterQuestions());

    const transitionCard = useTransition(currentItem, {
        from: {
            opacity: 0,
            transform: "scale(0.8) translate3d(0px,20%,0) rotate(0deg)",
        },
        enter: {
            opacity: 1,
            transform: "scale(1) translate3d(0px,0%,0) rotate(0deg)",
        },
        leave: {
            opacity: 0,
            transform: `scale(1) translate3d(${
                (200 + window.innerWidth) * direction.current
            }px,0%,0) rotate(45deg)`,
        },
    });

    const onSubmitAnswer = (payload) => {
        if (currentItem.type === types.SLIDER) {
            direction.current = payload < 5 ? -1 : 1;
            setAnswer({
                id: currentItem.id,
                type: currentItem.type,
                result: payload,
            });
        }

        if (currentItem.type === types.COMPETITION) {
            setAnswer({
                id: currentItem.id,
                type: currentItem.type,
                result: payload,
            });
        }

        if (currentItem.type === types.INTRO) {
            setAnswer({
                id: currentItem.id,
                type: currentItem.type,
            });
        }

        if (currentItem.type === types.OPEN) {
            setAnswer({
                id: currentItem.id,
                type: currentItem.type,
                result: payload,
            });
        }

        done.current.add(currentItem.id);

        if (done.current.size === list.length) {
            setTimeout(() => setCompleted(), 400);
            return;
        }

        return setCurrentItem(filterQuestions());
    };

    return (
        <Fragment>
            <div className="relative card-height">
                {transitionCard((styles, item) => (
                    <Card style={styles} item={item} />
                ))}
            </div>
            <div className="relative z-index">
                <Selectors onSubmitAnswer={onSubmitAnswer} item={currentItem} />
            </div>
        </Fragment>
    );
}

export default List;
