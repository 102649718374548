import React, { useState } from "react";

// Libraries
import { connect } from "react-redux";

// Core
import { createMeta } from "../../../core/session";
import Select from "react-select";

// Components
import Navigation from "../../components/navigation";
import Button from "../../components/buttons";
import { ages, arrangements } from "./config";

const Form = ({ createMeta }) => {
	const [age, setAge] = useState(null);
	const [street, setStreet] = useState("");
	const [arrangement, setArrangement] = useState(null);

	const [avg, setAvg] = useState(false);

	const submitForm = () => {
		createMeta({
			leeftijdscategorie: age,
			arrangement,
			street,
			avg,
		});
	};

	return (
		<div>
			<Navigation />
			<div className="flex flex-center min-h-100">
				<div className="pt-l pb-l container container-m center relative">
					<h3 className="fs-m a-l mb mt mb">
						In welke leeftijdscategorie valt u?
					</h3>
					<Select
						options={ages}
						blurInputOnSelect={true}
						isSearchable={false}
						onChange={({ value }) => setAge(value)}
					/>
					<h3 className="fs-m a-l mb mt">
						Aan welke straat woont u?
					</h3>
					<input
						className="input"
						placeholder="Type een straatnaam"
						onChange={({ target }) => setStreet(target.value)}
					/>
					<h3 className="fs-m a-l mb mt">
						Woont u in een huurwoning of een koopwoning?
					</h3>
					<Select
						options={arrangements}
						blurInputOnSelect={true}
						isSearchable={false}
						onChange={({ value }) => setArrangement(value)}
					/>

					<div className="flex flex-align mt-m">
						<input
							type="checkbox"
							className="checkbox mr"
							id="AVG"
							name="AVG"
							value={avg}
							onChange={() => setAvg(!avg)}
						></input>
						<label className="a-l" htmlFor="AVG">
							Ik ga akkoord met de onderstaande voorwaarden
						</label>
					</div>
					<div className="mt fs-xxs leading-tight a-l">
						Ik geef toestemming voor het verwerken van de hiervoor
						vermelde persoonsgegevens voor het uitvoeren en het
						maken van een buurtagenda ten behoeve van de
						vitaliteitsagenda. Uw gegevens zullen niet gebruikt
						worden voor andere doeleinden. De gegevens zullen
						vertrouwelijk worden gebruikt in overeenstemming met de
						privacyverklaring van de Eurus.
					</div>
					<Button
						text={"Volgende"}
						className="full mt-m mb-m"
						disabled={!avg}
						onClick={submitForm}
					/>
				</div>
			</div>
		</div>
	);
};

export default connect(null, { createMeta })(Form);
