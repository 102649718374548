import React from "react";

// Components
import Modal from "../modal";
import Container from "./container";

function Form({ open, toggle }) {
	return (
		<Modal open={open} toggle={toggle}>
			<div className="min-h-100 flex flex-center">
				<Container toggle={toggle} />
			</div>
		</Modal>
	);
}

export default Form;
