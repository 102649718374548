import { connect } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

// Core
import { getGameCompleted, getFormCompleted } from "../../core/session";

// Components
import Form from "./form";
import Game from "./game";
import Completed from "./completed";

const App = ({ gameCompleted, formCompleted }) => {
    if (!formCompleted) {
        return <Form />;
    }

    if (gameCompleted) {
        return <Completed />;
    }

    return <Game />;
};

const mapStateToProps = createSelector(
    getGameCompleted,
    getFormCompleted,
    (gameCompleted, formCompleted) => ({
        gameCompleted,
        formCompleted,
    })
);

export default connect(mapStateToProps, null)(App);
