import React, { useState } from "react";

// Libraries
import { connect } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

// Core
import { setNotification } from "../../../../core/notifications";
import { getUid } from "../../../../core/session";
import { db } from "../../../../core/firebase";

// Components
import Button from "../../buttons";

import { IconRight, IconSpin } from "../../icons";

function Container({ toggle, setNotification, uid }) {
	const [tip, changeTip] = useState("");

	const [loading, setLoading] = useState(false);

	const submitForm = async () => {
		setLoading(true);

		const response = await db
			.collection("tips")
			.doc(uid)
			.set({
				tip,
				createdAt: new Date(),
			})
			.then((doc) => ({ doc }))
			.catch((error) => ({ error }));

		setLoading(false);

		if (response.error) {
			setNotification({
				error: true,
				message:
					"Er is een fout opgetreden bij het opslaan van de informatie.",
			});
		} else {
			setNotification({
				message: "Het formulier is succesvol verstuurd!",
			});
			toggle();
		}
	};

	return (
		<div className="container container-m">
			<h3 className="fs-m a-l mb-s mt">
				Heeft u iets gemist of wilt u nog aanvullingen doen?
			</h3>
			<textarea
				className="textarea"
				placeholder="Aanvullingen"
				value={tip}
				type="text"
				onChange={(e) => changeTip(e.target.value)}
			/>

			<Button
				className="mt-m"
				text="Versturen"
				onClick={submitForm}
				disabled={!tip.length}
			>
				{loading ? (
					<IconSpin className="ml icon icon-sm spin" />
				) : (
					<IconRight className="ml icon icon-sm" />
				)}
			</Button>
		</div>
	);
}

const state = createSelector(getUid, (uid) => ({ uid }));
export default connect(state, { setNotification })(Container);
