import React, { useState } from "react";

// Libraries
import InputRange from "react-input-range";

// Components
import Button from "../../components/buttons";

function Slider({ onSubmitAnswer, decimal }) {
    const [value, setValue] = useState(5);

    const onChange = (payload) => {
        setValue(payload);
    };

    const submitAnswer = () => {
        onSubmitAnswer(value);
        if (decimal) {
            return;
        }
        setValue(5);
    };

    return (
        <div className="pt">
            <div className="relative mt-m">
                {!decimal && <div className="label label-min">Oneens</div>}
                <InputRange
                    step={1}
                    maxValue={10}
                    minValue={0}
                    value={value}
                    onChange={onChange}
                />
                <div className="label label-max">
                    {decimal ? `Rapportcijfer = ${value}` : "Eens"}
                </div>
            </div>
            <Button
                text="Volgende"
                className="mt-m full"
                onClick={submitAnswer}
            ></Button>
        </div>
    );
}

export default Slider;
