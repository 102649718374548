import { types } from "../config";

// Components
import Slider from "./slider";
import Button from "../../components/buttons";
import Open from "./open";

function Selectors({ onSubmitAnswer, setResult, result, item = {} }) {
    if (item.type === types.OPEN) {
        return (
            <Open
                result={result}
                setResult={setResult}
                onSubmitAnswer={onSubmitAnswer}
                {...item}
            />
        );
    }

    if (item.type === types.SLIDER) {
        return (
            <Slider
                result={result}
                setResult={setResult}
                onSubmitAnswer={onSubmitAnswer}
                {...item}
            />
        );
    }

    if (item.type === types.INTRO) {
        return (
            <Button
                text="Volgende"
                className="mt-m full"
                onClick={onSubmitAnswer}
            ></Button>
        );
    }

    return null;
}

export default Selectors;
