import React from "react";

// Components
import Image from "../images/image";

function Navigation() {
	return (
		<nav className="navigation container  flex flex-align flex-space">
			<Image
				height="60px"
				className="navigation-banner"
				src={`${process.env.PUBLIC_URL}/logos/logo-samen-sluisoord-demheen.svg`}
			/>
		</nav>
	);
}

export default Navigation;
